.setup {
    .setup-steps {
        .setup-step {
            .checkbox {
                color: var(--color-text);
                cursor: default !important;
                opacity: 1;
                transition: all $transition-duration $transition-type;
            }
            &.is-over {
                color: var(--color-gray2);
                text-decoration: line-through;
                .checkbox {
                    color: var(--color-gray2);
                    opacity: 0.5;
                }
            }
        }
    }
}