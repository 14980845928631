@import "~vue-multiselect/dist/vue-multiselect.min.css";

.multiselect {
    color: var(--color-text);
    min-height: initial;
}

/*.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 3rem;
  height: 2.1875rem;
  background: $spinnerBg;
  display: block;

  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: 0.875rem 0 0 0.875rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border-color: $spinnerBorderColor transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
  }
  &:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }
  &:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }
}
.multiselect__loading-transition {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: lighter;
}
.multiselect {
  box-sizing: content-box;

  * {
    box-sizing: border-box;
  }

  display: block;
  position: relative;
  width: 100%;
  min-height: 2.5rem;
  text-align: left;
  color: $multiselectColor;

  &:focus {
    outline: none;
  }

  &--active {
    z-index: 50;

    .multiselect__current,
    .multiselect__input,
    .multiselect__tags {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .multiselect__select {
      transform: rotateZ(180deg);
    }
  }
}
*/
.multiselect {
    min-width: 136px !important;
}
.multiselect__input,
.multiselect__single {
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    padding: 0;
    min-height: unset;
}
.multiselect__single,
.multiselect__option {
    .option-icon {
        margin-right: 0.5rem;
        &::before {

        }
    }
    .option-title {
        font-size: 14px;
        line-height: 1;
    }
}
.multiselect__tags {
    display: flex;
    align-items: center;
    min-height: 1.5rem;
    padding: 0 1.5rem 0 0.5rem;
    padding-right: 1.5rem !important;
    border-radius: var(--border-radius);
    font-size: 14px;
    line-height: 14px;
}
.multiselect--active {
    .multiselect__tags {
        .multiselect__input {
            max-width: 84px;
        }
    }
}

.multiselect__select {
    width: 30px !important;
    height: 100%  !important;
    right: 0  !important;
    top: 0  !important;
    transition: transform $transition-type $transition-duration;
    &:before {
        border-width: 4px 4px 0 !important;
        border-top-color: var(--color-text) !important;
    }
}

.multiselect__placeholder {

    .multiselect--active & {

    }
}

.multiselect__option {
    min-height: 20px;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    & > .option-title {

    }
    &--selected {

        &:after {

        }
    }
}
.multiselect__content-wrapper {
    border: none;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 0px 4px rgba(95, 186, 255, 0.3);
    .multiselect__content {
        .multiselect__element {
            .multiselect__option {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                &.multiselect__option--highlight {
                    background-color: var(--color-theme-dark);
                }
                &.multiselect__option--selected {
                    &.multiselect__option--highlight {
                        background-color: var(--color-theme-dark);
                    }
                }
            }
        }
    }
}

/*.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 0.25rem 1.625rem 0.25rem 0.625rem;
  border-radius: $multiselectTagsBorderRadius;
  margin-right: 0.625rem;
  color: $multiselectTagColor;
  line-height: 1;
  background: $multiselectTagBgColor;
  margin-bottom: 0.5rem;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 1.375rem;
  text-align: center;
  line-height: 1.375rem;
  transition: all 0.2s ease;
  border-radius: $multiselectTagsBorderRadius;

  &:after {
    content: $multiselectTagIcon;
    color: $multiselectTagIconColor;
    font-size: 0.875rem;
  }
  &:focus, &:hover {
    background: $multiselectTagIconColorHover;

    &:after {
      color: $multiselectTagIconColorBgHover;
    }
  }
}
.multiselect__current {
  line-height: 1rem;
  min-height: 2.5rem;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 0.5rem 0.75rem 0;
  padding-right: 1.875rem;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: $multiselectTagsBorderRadius;
  border: 1px solid $multiselectCurrentBorderColor;
  cursor: pointer;
}
.multiselect__content {
  position: absolute;
  list-style: none;
  display: block;
  background: $multiselectContentBgColor;
  width: 100%;
  max-height: 15rem;
  overflow: auto;
  padding: 0;
  margin: 0;
  border: 1px solid $multiselectContentBorderColor;
  border-top: none;
  border-bottom-left-radius: $multiselectTagsBorderRadius;
  border-bottom-right-radius: $multiselectTagsBorderRadius;
  z-index: 50;

  &::webkit-scrollbar {
    display: none;
  }
}
.multiselect__option {
  display: block;
  padding: 0.75rem;
  min-height: 2.5rem;
  line-height: 1rem;
  font-weight: 300;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;

  &:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 2.5rem;
    padding-right: 0.75rem;
    padding-left: 1.25rem;
  }
  &--highlight {
    background: $multiselectOptionHighlightBgColor;
    outline: none;
    color: $multiselectOptionHighlightColor;

    &:after {
      content: attr(data-select);
      color: $multiselectOptionHighlightColor;
    }
  }
  &--selected {
    background: $multiselectOptionSelectedBgColor;
    color: $multiselectOptionSelectedColor;
    font-weight: bold;

    &:after {
      content: attr(data-selected);
      font-weight: 300;
      color: darken($multiselectOptionSelectedBgColor, 20%);
    }
  }
}
.multiselect__option--selected.multiselect__option--highlight {
  background: $multiselectOptionHighlightSelectedBgColor;
  color: $multiselectOptionHighlightSelectedColor;
  font-weight: lighter;

  &:after {
    content: attr(data-deselect);
    color: $multiselectOptionHighlightSelectedColor;
  }
}
.multiselect--disabled {
  background: $multiselectDisabledBgColor;
  pointer-events: none;

  .multiselect__current,
  .multiselect__select {
    background: $multiselectDisabledBgColor;
    color: $multiselectDisabledColor;
  }
}
.multiselect__option--disabled {
  background: $multiselectDisabledBgColor;
  color: $multiselectDisabledColor;
  cursor: text;
  pointer-events: none;

  &:visited {
    color: $multiselectDisabledColor;
  }

  &:hover,
  &:focus {
    background: $multiselectDisabledOptionBgColor;
  }
}
.multiselect-transition {
  transition: all .3s ease;
}
.multiselect-enter, .multiselect-leave {
  opacity: 0;
  max-height: 0 ;
}*/

.rtl {
    .multiselect__single,
    .multiselect__option {
        .option-icon {
            margin-right: 0;
            margin-left: 0.125rem;
        }
    }
}
