// Framework's style override
.input {
    transition: all $transition-input-duration $transition-input-type;
}

.loading-overlay {
    z-index: 999;
}

a {
    color: var(--color-theme);
    transition: all $transition-type $transition-duration;
    opacity: 1;
    font-weight: 400;
    &:hover {
        color: var(--color-theme-dark);
    }
    &:active {
        color: var(--color-theme);
    }
    &:focus {
        outline: none !important;
    }
    &.is-black {
        font-weight: normal;
        color: var(--color-text);
        &:hover {
            color: var(--color-theme);
        }
        &:active {
            color: var(--color-theme-dark);
        }
    }
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
    z-index: 0;
}

.select select {
    border-radius: calc(var(--border-radius)/2);
}

.message {
    box-shadow: var(--shadow-material1);
}

.notices .toast {
    box-shadow: none;
    border-radius: 10px;
    padding: 1em 2em;
    margin: 1em 0;
    opacity: 1;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    &.is-success {
        background: $success;
    }
    &.is-danger {
        background: $danger;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity $transition-duration;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.modal {
    .form-inner {
        > .title {
            color: var(--color-white0);
        }
    }
}

.vux-uploader {
    padding: 0 !important;
    .vux-uploader_hd {
        .vux-uploader_info,
        .vux-uploader_title {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: var(--color-text);
        }
    }
    .vux-uploader_bd {
        .vux-uploader_input-box {
            border-radius: var(--border-radius) !important;
            border-color: var(--input-border-color) !important;
            &::before {
                width: 1px !important;;
            }
            &::after {
                height: 1px !important;;
            }
            &::before,
            &::after {
                background-color: var(--input-border-color) !important;
            }
        }
        .vux-uploader_files .vux-uploader_file {
            border-radius: var(--border-radius);
        }
    }
    .pswp {
        &.pswp--open {
            .pswp__bg {
                opacity: 0.9 !important;
            }
        }
    }
}
