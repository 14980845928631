.gotoform,
.successform {
    max-width: 640px;
    background-color: var(--color-white0);
    border-radius: var(--border-radius);
    padding: 3em 6em;
    margin: auto;
    @include mobile {
        width: 100%;
        padding: 1.5em;
    }
}

.successform {
    padding: 5em 6em 10em;
    @include mobile {
        padding: 2.5em 1.5em 5em;
    }
    img {
        margin-bottom: 3em;
    }
}

.form-inner {
    padding: 0;
    @include mobile {
        padding: 0.1em;
    }
}