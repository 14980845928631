.account {
    display: flex;
    align-items: center;
    .account-pic {
        margin-right: 0.25em;
        color: var(--color-gray1);
    }
    .account-info {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.25;
        .account-info-email {
            font-weight: 400;
            font-size: 12px;
            color: var(--color-gray2);
        }
        .account-info-phone {
            font-weight: 600;
        }
        > * {
            max-width: 14em;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        > span > .b-tooltip {
            display: inline;
            .tooltip-trigger {
                display: inline;
            }
        }
    }
}
