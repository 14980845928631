.form {
    .form-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
        margin-top: 1.5rem;
        &.is-last {
            margin-bottom: 0;
        }
        &.is-bottomspaced {
            margin-bottom: 1.5rem;
        }
        &.is-timer {
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 2.75em;
            &.is-last {
                margin-bottom: 0;
            }
            .goto-icon {
                margin-right: 0.5em;
            }
            .button {
                font-weight: normal;
                min-height: 24px;
                padding: 0;
                height: 24px;
                background: none;
                color: var(--color-theme);
            }
        }
        &.is-floated {
            label {
                font-weight: 300;
                font-size: 14px;
                line-height: 18px;
                color: var(--color-text);
            }
        }
        &.is-margin-top-short {
            margin-top: 0.5rem;
        }
        &.is-datepicker {
            label {
                position: absolute;
                top: 0;
                padding: 0.5rem 2px;
                transition: all $transition-input-duration $transition-input-type;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-gray2);
                z-index: 0;
                &.is-filled {
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--color-text);
                    transform: translate3d(0, -24px, 0);
                }
            }
            .datepicker {
                input {
                    padding-left: 2px;
                }
            }
        }
        .floating-control {
            position: relative;
            .label {
                position: absolute;
                top: 0;
                padding: 0.5rem 2px;
                transition: all $transition-input-duration $transition-input-type;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-gray2);
                z-index: 0;
            }
            .input {
                padding: 0.5rem 2px;
                padding-right: 2.25em;
                z-index: 1;
                border-top: none;
                border-right: none;
                border-left: none;
                background: transparent !important;
                border-radius: 0;
                box-shadow: none;
                &:focus,
                &:active {
                    box-shadow: none;
                }
                &:focus + .label,
                &:valid + .label,
                &:disabled + label {
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--color-text);
                    transform: translate3d(0, -24px, 0);
                }
                &:focus + .label {
                    color: var(--color-theme);
                }
                &.is-danger + .label {
                    color: $danger;
                }
                &:disabled {
                    color: var(--color-gray1);
                    border-color: var(--input-border-color);
                }
            }
        }
    }
    .form-checkbox {
        display: flex;
        align-items: flex-start;
        @include tablet {
            align-items: center;
        }
        &:hover input[type=checkbox]:not(:disabled) + .check{
            border-color: $success;
        }
        input[type=checkbox] {
            + .check {
                border: 1px solid var(--color-gray2);
                border-radius: 3px;
                transition: all $transition-type $transition-duration;
                margin: 4px 0;
            }
            &:focus + .check {
                box-shadow: none;
            }
            &:checked + .check {
                background: $success url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.375 2.95109L5.63176 6.375L12.625 0.75' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center center;
                border-color: $success;
            }
        }
        .control-label {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .form-text {
        font-size: 14px;
        padding: 2rem 0;
    }
    .field {
        .control + .block {
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
        &.is-grouped {
            .control:not(:last-child) {
                display: flex;
                &.is-expanded {
                    display: block;
                }
            }
            .control.is-radio .field {
                display: flex;
            }
        }
    }
}
