@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700&display=swap&subset=cyrillic");

:root {
    // INITIAL COLORS
    --color-white0: #ffffff;
    --color-white1: #fcfcfc;
    --color-white2: #f7f7f7;
    --color-white3: #f5f6fa;

    --color-gray1: #e0e0e0;
    --color-gray2: #bcbcbc;

    // THEME COLORS
    --color-text: #3e3e3e;
    --color-theme: #5fbaff;
    --color-theme-dark: #0091ff;

    // DERIVED COLOR
    --body-background-color: var(--color-white3);
    --footer-background-color: var(--color-white2);
    --input-border-color: var(--color-gray1);
    --box-background-color: var(--color-white0);

    //MISC
    --border-radius: 10px;
    --font-main: "IBM Plex Sans";

    //LEGACY
    --shadow1: 3px 3px 10px 2px rgba(30, 136, 229, 0.1);
    --shadow-material1: 0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
}

//CUSTOMIZING FRAMEWORK DEFAULTS
$radius: var(--border-radius);

$transition-type: ease;
$transition-duration: 0.3s;
$transition-button-type: $transition-type;
$transition-button-duration: $transition-duration;
$transition-input-type: $transition-type;
$transition-input-duration: $transition-duration;

$family-sans-serif: var(--font-main), BlinkMacSystemFont, -apple-system,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$success: #6fcf97;
$danger: #eb5757;
$grey-dark: #3e3e3e;
$primary: #5fbaff;
$primary-invert: #ffffff;

$body-background-color: var(--body-background-color);
$body-color: var(--color-text);
$footer-background-color: var(--footer-background-color);

$box-color: var(--color-text);
$title-color: var(--color-text);
$title-weight: 700;
$subtitle-color: var(--color-text);
$content-heading-color: var(--color-text);

$label-color: $title-color;
$label-weight: normal;
$input-color: $body-color;
$input-border-color: var(--input-border-color);
$input-hover-border-color: var(--color-gray2);
$input-focus-border-color: var(--color-theme);
$input-radius: calc(var(--border-radius) / 2);
// $input-shadow: none;

$box-background-color: var(--box-background-color);
$box-radius: var(--border-radius);
$box-shadow: none;

$checkbox-border-radius: calc(var(--border-radius) / 2);
$checkbox-active-background-color: var(--color-theme);

$navbar-breakpoint: 640px;

$message-radius: calc(var(--border-radius) / 3);
$message-body-radius: calc(var(--border-radius) / 3);
$message-header-radius: calc(var(--border-radius) / 3);

$progress-value-background-color: var(--color-theme);

//CUSTOMIZING SIDEBAR DEFAULTS
$primary-color: var(--color-theme-dark);
$item-hover-bg: transparent !important;
$item-active-color: var(--color-white0) !important;
$icon-bg: transparent !important;
