.v-sidebar-menu {
    z-index: 0;
    .vsm--list {
        padding: 0 1em 0 1em;
        transition: $transition-type $transition-duration;
        hr {
            margin-left: -1em;
            margin-right: -1em;
        }
        .vsm--header {
            padding: 1rem;
        }
        .logo {
            padding: 0.5rem 1rem;
            min-height: 56px;
        }
        .vsm--item {
            &:not(:last-child) {
                margin-bottom: 0.25em;
            }
            &.vsm--item_open {
                .vsm--link_level-1 {
                    color: var(--color-text) !important;
                    background-color: var(--color-white0) !important;
                    box-shadow: none;
                    .vsm--icon {
                        color: var(--color-text) !important;
                    }
                    &:hover {
                        color: var(--color-text) !important;
                        background-color: transparent !important;
                    }
                }
            }
            .vsm--link {
                display: flex;
                padding: 1em;
                border-radius: calc(var(--border-radius) / 2);
                font-weight: 500;
                line-height: 1;
                transition: $transition-type $transition-duration;
                &:hover {
                    opacity: 1;
                    color: var(--color-text) !important;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 1.25em;
                        height: 3em;
                        background-color: var(--color-theme-dark) !important;
                        display: block;
                        border-radius: calc(var(--border-radius) / 2);
                        left: 0;
                        transform: translate(-2em, 0);
                        transition: $transition-type $transition-duration;
                    }
                }
                &.vsm--link_exact-active,
                &:hover.vsm--link_exact-active {
                    color: var(--color-white0) !important;
                    background-color: var(--color-theme-dark) !important;
                    box-shadow: none;
                    .vsm--icon {
                        color: var(--color-white0) !important;
                    }
                }
                &:active {
                    background-color: var(--color-theme) !important;
                    color: var(--color-white0);
                    &:after {
                        background-color: var(--color-theme);
                    }
                    .vsm--icon {
                        color: var(--color-white0);
                    }
                }
                .vsm--icon {
                    display: inline-flex;
                    height: 14px;
                    width: 20px;
                    margin-right: 0.75em;
                    justify-content: flex-start;
                    transition: $transition-type $transition-duration;
                    svg {
                        height: 14px;
                    }
                }
            }
        }
    }
    .vsm--dropdown .vsm--list {
        background-color: transparent !important;
    }
    .vsm--toggle-btn {
        outline: none;
    }
    &.vsm_collapsed {
        .vsm--list {
            padding: 0;
            .logo {
                padding: 0.5rem;
            }
            .vsm--item {
                .vsm--link {
                    border-radius: 0;
                }
            }
        }
    }
}
