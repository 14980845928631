html {
    overflow: auto !important;
}

#app {
    height: 100vh;
    margin-bottom: 0;
    padding-left: 14em;
    transition: 0.3s padding ease;
    &.collapsed {
        padding-left: 3.125em;
    }

    .appcontent {
        padding: 2em 2em 0 2em;
        margin-bottom: 2em; // for jivo widget
        @include touch {
            padding: 1em 1em 0 1em;
        }
    }
}

.table {
    .fa-stack {
        font-size: 0.675em;
        height: auto;
        position: initial;
    }
}

.buyhardware {
    margin-top: 4rem;
    .buyhardware-item {
        .buyhardware-item-img {
            max-height: 90px;
        }
        svg + p {
            margin-top: 0.5rem;
        }
    }
}
